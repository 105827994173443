.sidebar {
  background-color: #eee;
  height: 100vh;
  width: 160px;
}

.dndflow {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside > * {
  margin-bottom: 10px;
  cursor: grab;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.entrada {
  margin-bottom: 6px;
  margin-left: 12px;
	width: 138px;
	/* height: 40px; */
	transform: skew(-20deg);
	/* background: #555; */
  border: 1px solid #555;
  padding: 10px;
  font-size: 12px;
  background: #fcfcfc;
  text-align: center;
}
.entrada strong {
  padding-top: 100px;
}
.saida {
  margin-bottom: 6px;
  margin-left: 12px;
	width: 138px;
	/* height: 40px; */
	/* background: #555; */
  border: 1px solid #555;
  border-radius: 0 50% 50% 0;
  padding: 10px;
  font-size: 12px;
  background: #fcfcfc;
  text-align: center;
}
.saida strong {
  padding-top: 100px;
}

.processo {
  margin-bottom: 6px;
  margin-left: 12px;
	width: 138px;
	/* height: 40px; */
	/* background: #555; */
  border: 1px solid #555;
  /* border-radius: 0 50% 50% 0; */
  padding: 10px;
  font-size: 12px;
  background: #fcfcfc;
  text-align: center;
}
.processo strong {
  padding-top: 100px;
}
.condicao {
  transform:rotate(45deg);
  /* margin-bottom: 6px;
  margin-left: 75%; */
  margin: 15px 55px;
	width: 60px;
	height: 60px;
	/* height: 40px; */
	/* background: #555; */
  border: 1px solid #555;
  /* border-radius: 0 50% 50% 0; */
  /* padding: 10px; */
  font-size: 12px;
  background: #fcfcfc;
  text-align: center;
}
.condicao div {
  width: 60px;
	height: 60px;
  padding-top: 14px;
  padding-right: 6px;
  text-align: center;
  transform:rotate(-45deg);
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 180px;
  }
}